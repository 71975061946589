






















import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    // comment ID
    id: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup(props, { root, emit }) {
    const form = ref<any>(null)

    const state = reactive({
      isFormValid: true,
      active: false,
      loading: false
    })

    const model = reactive({
      comment: ''
    })

    const rules = {
      comment: [(v: string) => !!v || 'Podaj treść komentarza']
    }

    const editComment = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      const data = { text: model.comment }
      state.loading = true

      axiosInstance
        .put(`comment/${props.id}`, data)
        .then(() => {
          state.active = false
          emit('edit')
        })
        .catch(error => console.log(error))
        .finally(() => state.loading = false)
    }

    const onSubmit = async () => {
      await form.value.validate()
      if (state.isFormValid) editComment()
    }

    onMounted(() => model.comment = props.text)

    return { form, state, model, rules, onSubmit }
  }
})
